import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import "./order-created.css";
import orderCreated from "../../images/imgs/Order-created.png";
import MainButton from "../../Components/Common/MainButton";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
const OrderCreated = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <Container fluid className="order-created py-5">
      <div className="text-center pt-5 px-4">
        <h1 className="text-white">Ваш заказ принят!</h1>
        <img src={orderCreated} alt="Ok" width={190} height={240} className="mt-2" />
        <div className="mt-4">
          <span className="text-white">
            Заказ #
            {searchParams
              .get("orderId")
              .slice(searchParams.get("orderId").length - 6)}
          </span>
          <br />
          <span className="text-white">ID: {searchParams.get("userId")}</span>
          <div
            className="mt-4"
            onClick={() => {
              window.Telegram.WebApp.openTelegramLink("https://t.me/snusngs_2");
            }}
          >
            <MainButton
              bgColor="white"
              color="#FF9400"
              text="Связаться с менеджером"
              width="100%"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OrderCreated;
