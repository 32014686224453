import React, {useEffect, useState} from "react";
import { Col, Row } from "react-bootstrap";
import { AddressSuggestions } from "react-dadata";
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import YMapsMap from "./YMapsMap";

const DeliveryFields = ({
  isInsideMKAD,
  setIsInsideMKAD,
  setAddress,
  phone,
  setPhone,
  comment,
                          setIsOutsideMkad,
  setComment,
  changePromo,
  checkPromo,
  isPromo,
  togglePromo,
  isFreeDelivery,
                          promo,
                          totalQuantity,
  isPromoActivated,
  address,
  setGeol,
  geo
}) => {



  return (
    <div className="delivery-hidden">
      <div className="delivery-address">
        <input
          placeholder="Тел:"
          value={phone}
          onChange={(e) => setPhone(e.target.value.trim())}
          type="tel"
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
        />
        <input
          placeholder="Комментарий:"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
        />
        <p onClick={togglePromo} className="mt-2" style={{cursor: 'pointer', paddingBottom: 0, marginBottom: 0, textDecoration: "underline"}}>Есть промокод?</p>
        <div className={`${isPromo ? "d-flex" : "d-none"} justify-content-between align-items-end gap-4`}>
          <input
            placeholder="Промокод:"
            className="w-50"
            onChange={(e) => {
              changePromo(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.target.blur();
              }
            }}
          />
          {promo !== '' && <p className={`${(isFreeDelivery && !isPromoActivated) ? (totalQuantity >= 5 ? 'text-success' : 'text-danger') : 'text-danger'} mb-0 pb-0`} style={{ fontSize: '13px' }}>{isFreeDelivery ? (totalQuantity >= 5 ? (!isPromoActivated ? 'Верный промокод' : 'Действует только на первый заказ')  : 'Минимальное количество товаров - 5 шт') : (isPromoActivated ? 'Действует только на первый заказ' : 'Неверный промокод')}</p>}
        </div>
      </div>
    </div>
  );
};

export default DeliveryFields;
